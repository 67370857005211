$(function() {
　$('.toggle').click(function() {

    // ハンバーガーメニュー
    $(this).toggleClass('active');
    $('.menu').toggleClass('open');
    $("body").addClass("is-fixed");

    // 背景の固定
    if ($(this).hasClass("is-active")) {
      $("body").removeClass("is-fixed");
    } else {
      $("body").addClass("is-fixed");
    }
    $(".toggle").toggleClass("is-active");

  });
});




// Ｑ＆Ａ
$(function() {

  $('.qa_open_btn').click(function() {
    // ハンバーガーメニュー
    $(this).toggleClass('active');
    $('.qa_menu').toggleClass('open');

    // $("body").addClass("is-fixed");

    // 背景の固定
    // if ($(this).hasClass("is-active")) {
    //   $("body").removeClass("is-fixed");
    // } else {
    //   $("body").addClass("is-fixed");
    // }
    // $(".qa_open_btn").toggleClass("is-active");
  });

});


$(function() {

  $('.qa_search_open').click(function() {
    // ハンバーガーメニュー
    $(this).toggleClass('active');
    $('.qa_search_menu').toggleClass('open');
  });

});